import React, { useState, useEffect } from 'react';
import MenuFooter from '../../components/MenuFooter';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const UserPoints = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
    const [cookies] = useCookies();

    const { auth, setModal, setShowModal, setIsLoading } = useAuth();
    const [display, setDisplay] = useState(false);
    const [avatarImage, setAvatarImage] = useState();
    const [, setUser] = useState();
    const [, setUserDetails] = useState();
    const [dietaryMainConstraintName, setDietaryMainConstraintName] = useState('');
    const [dietaryMainConstraintAvatar, setDietaryMainConstraintAvatar] = useState('');
    const [allergens, setAllergens] = useState([]);
    const [kcal, setKcal] = useState(0);
    const [co2, setCO2] = useState(0);
    const [, setRanking] = useState();
    const axiosPrivate = useAxiosPrivate();
    const today = new Date()
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    let currentDay = new Date()
    
    const userFirstName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['name'] ?? cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName'] ?? ' '
    const userLastName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName'] ?? ' '

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    const getUser = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests', {
            params: {
                childId: auth.childId,
            },
            withCredentials: true
        })
        let data = result.data;
        data.unsolds = 0
        setUser(data);
        await getRanking();
        setDisplay(true);

        const detailsResult = await axiosPrivate.get(`/guests/details?childId=${auth.childId}`, {
            withCredentials: true
        })
        let responseData = detailsResult.data;
        setUserDetails(responseData);
        setAvatarImage(responseData.avatar);
        setDietaryMainConstraintName(responseData.dietaryMainConstraint.name)
        setDietaryMainConstraintAvatar(responseData.dietaryMainConstraint.avatar)
        setAllergens(responseData.allergens)
        setKcal(responseData.kcal)
        setCO2(responseData.co2)
        setIsLoading(false);
    };

    const getRanking = async () => {
        const result = await axiosPrivate.get('/guests/ranking', { withCredentials: true })
        let data = result.data;
        setRanking(data);
        console.log(data)
    };

    const saveAvatar = (avatar) => {
        axiosPrivate.put('/guests/avatar', {'avatar': avatar, 'childId': auth.childId}, { withCredentials: true })
        setShowModal(false)
        setAvatarImage(avatar)
    }

    const chooseAvatar = () => {
        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Choisis ton avatar</div>,
            content: <div className='d-flex justify-content-center align-items-center px-4' style={{flexWrap: 'wrap'}}>
                <div onClick={() => saveAvatar('avatar-girl-1.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-1.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-girl-2.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-2.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-girl-3.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-3.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-girl-4.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-4.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-girl-5.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-5.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-girl-6.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-6.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-1.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-1.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-2.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-2.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-3.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-3.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-4.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-4.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-5.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-5.png' alt='Avatar'></img></div>
                <div onClick={() => saveAvatar('avatar-boy-6.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-6.png' alt='Avatar'></img></div>
            </div>
        })
        setShowModal(true)
    }

    useEffect(() => {
        getUser();
    }, []);

    let trophy = false

    return ( display &&
        <div className='home-page d-flex my-points min-vh-100'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='meal-header' style={{position: 'relative'}}>
                    <div style={{fontWeight: 600, fontFamily: 'Poppins', fontSize: '22px'}}>Mon profil</div>
                    <Link to='/my-profile' style={{display: 'contents'}}>
                        <div className='header-points d-flex flex-grow-1 justify-content-end' style={{backgroundColor: 'transparent'}}>
                            <i style={{color:'#FF5001', fontSize: '24px'}} className="fa-solid fa-gear"></i>
                        </div>
                    </Link>
                </div>

                <div style={{fontWeight: 600, fontSize: '18px', margin: '25px 0'}}>Données personnelles</div>

                <div className='d-flex justify-content-center align-items-center' style={{backgroundColor: 'white', padding: '10px 15px', borderRadius: '10px'}}>
                    <div role='presentation' className='profile-card-avatar' onClick={chooseAvatar}><img onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src='/images/avatar-no-image.png';}} style={{margin: '0 15px', width: '115px'}} src={`images/${avatarImage}`} alt='Avatar'></img></div>

                    <div className='d-flex flex-column profile-card-infos'>
                        <div className='profile-card-name'>{userFirstName} {userLastName}</div>
                        <div className='profile-card-cumul-points'>{userPoints} points cumulés</div>
                        <div className='profile-card-points'>Solde au {today.getDate().toString().padStart(2, '0') + '/' + (today.getMonth()+1).toString().padStart(2, '0') + '/' + today.getFullYear()}</div>
                        <hr></hr>
                    </div>
                </div>

                <div className='d-flex justify-content-around align-items-center' style={{backgroundColor: 'white', padding: '10px 15px', borderRadius: '10px', margin: '15px', width: 'calc(100% - 50px)'}}>
                    <div className='profile-card-avatar'><img style={{ width: '84px', marginRight: '10px', background: '#ff5001'}} src={`/images/${dietaryMainConstraintAvatar}`} alt={dietaryMainConstraintName}></img></div>

                    <div className='d-flex flex-column profile-card-infos'>
                        <div style={{fontSize: '18px', fontWeight: 600, marginBottom: '10px'}}>{dietaryMainConstraintName}</div>
                        <div style={{fontSize: '14px'}}>Allergènes</div>
                        <div className='d-flex' style={{flexWrap: 'wrap'}}>
                            { allergens.map((allergen, key) => (
                                <div key={key} style={{marginLeft: '5px'}}><img style={{width: '24px', borderRadius: '15px'}} src={`/images/${allergen}`} alt=''></img></div>
                            )) }
                        </div>
                    </div>
                </div>

                <div style={{fontSize: '20px', fontWeight: 600, fontFamily: 'Poppins', margin: '10px', textTransform: 'capitalize', marginTop: '30px'}}>{completeMonths[currentDay.getMonth()]}</div>

                <div className='d-flex' style={{marginBottom: '80px'}}>
                    <Link to='/kcal-details'>
                        <div className='d-flex flex-column' style={{minHeight: '200px', width: '175px', backgroundColor: 'white', padding: '10px 15px', borderRadius: '10px', margin: '10px'}}>
                            <div className='avatar-high-number'>{kcal}</div>
                            <div className='avatar-high-unity'>kcal / 100g</div>
                            <div style={{textAlign: 'center'}}>Apports nutritionnels</div>
                        </div>
                    </Link>

                    <Link to='/co2-details'>
                        <div className='d-flex flex-column' style={{minHeight: '200px', width: '175px', backgroundColor: 'white', padding: '10px 15px', borderRadius: '10px', margin: '10px'}}>
                            <div className='avatar-high-number'>{co2}</div>
                            <div className='avatar-high-unity' style={{fontSize: '20px'}}>kg CO2 / 100g</div>
                            <div style={{textAlign: 'center'}}>Emissions de carbone</div>
                        </div>
                    </Link>
                </div>

                {trophy &&
                    <div className='d-flex justify-content-center align-items-center' style={{backgroundColor: 'white', padding: '10px 15px', borderRadius: '10px', margin: '0 10px 80px'}}>
                        <div className='profile-card-avatar' style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}><img src='/images/eco-trophy.png' alt='Trophée'></img></div>

                        <div className='d-flex flex-column profile-card-infos'>
                            <div style={{fontSize: '18px', fontWeight: 'bold'}}>Eco-Champion</div>
                            <div style={{fontSize: '14px', marginTop: '10px'}}>Tu as réduit ton gaspillage alimentaire de 50% ou plus sur un mois</div>
                        </div>
                    </div> }


                <MenuFooter item='jabu' unsoldsRemain={0}></MenuFooter>
            </div>
        </div>
    )
}

export default UserPoints;
