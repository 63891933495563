import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const CGUPage = () => {
    const { auth } = useAuth();
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
    
    return (
        <div className="profil-page d-flex cgu min-vh-100 justify-content-center">
            <div className="d-flex flex-column align-items-center flex-grow-1 flex-shrink-1">
                { auth?.username &&
                    <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                        <div className="d-flex profile-header-back">
                            <Link to='/my-profile'>
                                <span style={{borderRadius: '20px', padding: '10px' }}>
                                    <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Mon profil</div>
                    </div>
                }

                { null == auth?.username &&
                    <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                        <div className="d-flex profile-header-back">
                            <Link to='/register'>
                                <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Retour</div>
                    </div>
                }   

                <div className="d-flex flex-column profile-block">
                    <div className="user-title d-flex justify-content-center">
                        CONDITIONS GÉNÉRALES DE VENTE ET D&apos;UTILISATION
                    </div>

                    <div className="cgu d-flex flex-column">
                        <p style={{fontWeight: 'bold'}}>PRÉAMBULE</p>
                        <p>Les présentes conditions générales de vente et d’utilisation (ci-après « les CGVU ») s’appliquent : </p>
                        <p>aux conditions d&apos;accès et d&apos;utilisation du site disponible à l&apos;Adresse  « www.jabu-app.fr » (ci-après le « Site »)  et de l&apos;Application (tel que ce terme est défini ci-après) disponible à l&apos;Adresse « www.jabu-app.com » ainsi que sur l&apos;App Store® d’Apple, Inc. et le Google Play Store® d’Alphabet, Inc. ;</p>
                        <p>aux conditions d&apos;accès et d’utilisation des Services (tel que ce terme est défini ci-après) accessibles à partir de l&apos;Application ; et</p>
                        <p>à la commande, par l&apos;Utilisateur (tel que ce terme est défini ci-après), à travers le Site ou l&apos;Application, de tout Service. </p>
                        <p>Le Site et l&apos;Application sont exploités par la Société (tel que ce terme est défini ci-après) et les Services sont, sauf mention contraire stipulées aux présentes, réalisés par cette même Société.</p>
                        <p>La consultation du Site et de l&apos;Application par tout Utilisateur, ainsi que la commande de Services sont subordonnées à la consultation, l&apos;acceptation préalable et au respect intégral des présentes CGVU par l&apos;Utilisateur, en ce compris la Politique de confidentialité (telle que définie en Annexe des présentes et disponible en <Link to='/confidential'>cliquant ici</Link>). </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE PRÉLIMINAIRE - DÉFINITIONS </p>
                        <p>Les mots ou expressions commençant avec une majuscule ont la signification qui suit : </p>
                        <p>Abonnement : désigne le ou les services identifiés à l&apos;Article 2.1.1. ci-après ;</p>
                        <p>Application : désigne la solution logicielle applicative de type « logiciel en tant que service » permettant l&apos;Accès aux Services disponible à l&apos;Adresse « www.jabu-app.com » ainsi que sur l&apos;App Store® d’Apple, Inc. et le Google Play Store® d’Alphabet, Inc. dont les mentions légales sont disponibles en  cliquant ici;</p>
                        <p>CGVU : désigne le présent document contractuel faisant état des Conditions Générales de Vente et d’Utilisation ; </p>    
                        <p>Partie(s) : désigne indifféremment la Société ou l&apos;Utilisateur, ensemble ou séparément ;  </p>    
                        <p>Politique de confidentialité : désigne la Politique de confidentialité, partie intégrante des présentes, disponible en <Link to='/confidential' target='_blank'>cliquant ici</Link> ;</p>    
                        <p>Service(s) : désigne le ou les services identifiés à l&apos;Article 2 ci-après, en ce compris tout Abonnement ; </p>    
                        <p>Site : désigne le présent site internet accessible à l&apos;Adresse : www.jabu-app.fr) dont les mentions légales sont disponibles en <Link to='https://www.jabu-app.fr/mentions-l%C3%A9gales' target='_blank'>cliquant ici</Link> ;</p>    
                        <p>Société : désigne la société JABU, société par actions simplifiée au capital de 1.000 euros, dont le siège social est sis 26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS, immatriculée au registre du commerce et des sociétés du Greffe du Tribunal de Commerce de la ville de BOBIGNY sous le numéro d&apos;identification unique 980 078 091, assujettie à la taxe sur la valeur ajoutée intracommunautaire sous le numéro FR 64 980 078 091 ;</p>    
                        <p>Utilisateur(s) Professionnel(s) : désigne toute personne qui visite une ou plusieurs pages du Site et, plus globalement, utilise le Site ou l&apos;Application, que ce soit pour consulter les pages immédiatement et gratuitement disponibles ou pour commander des Services ayant la qualité de professionnels de la restauration, de l’hôtellerie ou de la restauration collective ;</p>    
                        <p>Utilisateur(s) Particulier(s) : désigne toute personne qui visite une ou plusieurs pages du Site et, plus globalement, utilise le Site ou l&apos;Application, que ce soit pour consulter les pages immédiatement et gratuitement disponibles ou pour commander des Services à l&apos;exception de tout Utilisateur Professionnel ; et</p>    
                        <p>Utilisateur(s) : désigne indifféremment les Utilisateurs Professionnels et Particuliers. </p>    
                        <p></p>    
                        <p style={{fontWeight: 'bold'}}>ARTICLE 1 - ACCÈS AU SITE ET AUX SERVICES</p>    
                        <p>La consultation du Site et de l&apos;Application est accessible gratuitement à tout Utilisateur disposant d&apos;un accès internet.</p>    
                        <p>l&apos;ensemble des coûts afférents à l&apos;accès au Site ou à l&apos;Application, que ce soient les frais matériels, logiciels ou d&apos;accès à internet sont exclusivement à la charge de l&apos;Utilisateur. l&apos;Utilisateur est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès internet.</p>    
                        <p>Le Site comporte une partie accessible à tout Utilisateur et une partie accessible aux Utilisateurs ayant créé un compte. l&apos;Application et l&apos;Accès aux Services ne sont disponibles qu’aux Utilisateurs ayant créé un compte.</p>    
                        <p>Le processus pour créer un compte est accessible ici : <Link to='/login'>https://www.jabu-app.com/login</Link></p>    
                        <p>Pour ce faire, l&apos;Utilisateur est invité à communiquer de manière obligatoire les données à caractère personnel suivantes : nom, prénom, âge, email, mot de passe. l&apos;Utilisateur personne morale pourra se voir demander des informations lui étant propres : dénomination sociale,  forme sociale, adresse physique, nom et prénom du ou des représentants légaux, montant du capital social, numéro d&apos;identification au registre du commerce et des société, numéro d’assujettissement à la taxe sur la valeur ajoutée intracommunautaire, nom et prénom de la personne physique responsable de la gestion du compte et des rapports avec la Société, intitulé du poste de cette même personne. </p>
                        <p>Dans le cadre de la création de ce compte, l&apos;Utilisateur s’engage à fournir des informations exactes et à jour.</p>
                        <p>Une fois les formulaires dédiés à la création du compte complétés, un courrier électronique de confirmation sera automatiquement généré et envoyé sur l&apos;Adresse renseignée comprenant un lien hypertexte renvoyant vers les présentes CGVU et vers la Politique de confidentialité.</p>
                        <p>Certaines parties du Site et de l&apos;Application peuvent, en outre, être soumises au règlement par l&apos;Utilisateur d’un droit d’accès. Ce droit d’accès peut prendre la forme d’une souscription à un Abonnement. La procédure de souscription de l&apos;Abonnement, le détail des modalités de souscription de l&apos;Abonnement et les services auxquels l&apos;Abonnement donne accès sont décrits à l&apos;Article 2.1.1. ci-après.</p>
                        <p>l&apos;Utilisation du Site et de l&apos;Application ainsi que la création d’un compte implique l&apos;Adhésion entière et sans réserve de l&apos;Utilisateur aux présentes CGVU, à l&apos;exclusion de toute autre disposition contractuelle, notamment les éventuelles conditions générales d’achat de l&apos;Utilisateur. </p>
                        <p>La Société se réserve le droit de refuser l&apos;accès aux Services, à tout Utilisateur ne respectant pas les présentes CGVU. </p>
                        <p>Il est précisé à titre purement informatif que la Société fera ses meilleurs efforts pour permettre l&apos;Accès au Site et à l&apos;Application vingt-quatre (24) heures sur vingt-quatre (24), sept (7) jours sur sept (7), sauf cas de force majeure (tel que défini à l&apos;Article 1218 du code civil), cas fortuit ou fait d’un tiers. Cependant, la Société se réserve le droit :</p>
                        <p>de procéder à toute interruption du Site ou de l&apos;Application pour raison de maintenance technique ou de mise à jour ou pour toute autre raison ;</p>
                        <p>de suspendre, interrompre ou de limiter, à tout moment et pour la durée de son choix (en ce inclus tout arrêt définitif) sans avis préalable, l&apos;accès à tout ou partie du Site. </p>
                        <p>l&apos;Utilisateur est informé que la Société peut mettre fin ou modifier les caractéristiques des Services offerts sur le Site et l&apos;Application.</p>
                        <p>La Société n’est pas tenue d’une obligation de résultat concernant l&apos;Accessibilité au Site ou à l&apos;Application, et n&apos;est en aucun cas responsable des interruptions et des conséquences qui peuvent en découler. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 2 – CARACTÉRISTIQUES DES SERVICES – PRIX – MODALITES DE PAIEMENT</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1. SERVICE - PRIX</p>
                        <p>Les Services accessibles via l&apos;Application sont identifiés au présent article 2 (étant à ce titre précisé, en tant que de besoin, que tous services autres que ceux identifiés ci-dessous ont vocation à être régis par des conditions générales de vente ou d’utilisation leur étant propres, en considération, par exemple, du fait que ces derniers sont déployés par des partenaires de la Société et non par la Société ou de la nature spécifique de ces services).</p>
                        <p>Il est précisé que les caractéristiques des Services peuvent être amenées à varier en fonction de l&apos;évolution des dispositions législatives et réglementaires qui leur sont applicables et/ou des évolutions requises pour adapter ou dynamiser les Services ou produits concernés.</p>
                        <p>l&apos;Accès ou l&apos;Utilisation des Services ci-après désignés, nécessite l’ouverture d’un compte tel que stipulé à l&apos;Article 1 ci-dessus et, dans certaines hypothèses, la souscription d’un Abonnement. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.1. ABONNEMENT</p>
                        <p>Les abonnements ont vocation à être souscrits par des Utilisateurs Professionnels. Ce service ne fait pas l’objet d’une commercialisation auprès du consommateur au sens du Code de la consommation mais uniquement de personnes morales.</p>
                        <p>Le détail et le prix de ce service sera obtenu sur devis en prenant contact à l&apos;Adresse : contact@jabu-app.com.</p>
                        <p>Le descriptif des services précis auxquels donne accès l&apos;Abonnement peut varier en fonction de l&apos;Abonnement effectivement souscrit, la Société s’autorisant à proposer plusieurs niveaux d’abonnements aux Utilisateurs Professionnels en fonction du prix effectivement payé. Sous cette réserve, il est ici précisé qu’un abonnement sera notamment nécessaire pour accéder à la partie de l&apos;Application permettant de :</p>
                        <p>générer, organiser et contrôler l&apos;Accès à des menus ;</p>
                        <p>générer, organiser et contrôler l&apos;Accès à des calendriers de réservation ;</p>
                        <p>gérer le réassort ; et de</p>
                        <p>proposer des annonces relatives à la vente de produits alimentaires. </p>
                        <p>Le devis comprenant le descriptif des services de l&apos;Abonnement souscrit constitue des conditions particulières formant un ensemble contractuel indivisible avec les présentes CGVU. À ce titre, il est précisé qu’en cas de nullité de toute stipulation contractuelle, seule la clause entachée de nullité sera réputée non écrite et que cette nullité partielle de l&apos;ensemble contractuel n’emportera pas la nullité des autres clauses des présentes. </p>
                        <p>Les éléments suivants sont notamment demandés à l&apos;effet de créer un compte d’abonné : numéro de d&apos;inscription au registre du commerce et des société ou le code d&apos;identification au système d&apos;identification du répertoire des entreprises (SIREN) (si la personne est assujettie), dénomination, numéro de taxe sur la valeur ajoutée intracommunautaire, nom, prénom, fonction, téléphone fixe, téléphone mobile, adresse de correspondance, adresse de facturation, nom, prénom, téléphone, email et fonction de la personne physique de contact email, IBAN, BIC.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.2. SERVICE DE GESTION DES MENUS</p>
                        <p>Le service de gestion des menus permet aux Utilisateurs Professionnels de créer des menus alimentaires (c’est-à-dire une liste de plats servis à un repas), de modifier les menus, de déterminer les Utilisateurs Particuliers susceptibles de consulter les menus, de soumettre les menus à l&apos;Approbation des Utilisateurs Particuliers et de supprimer des menus. </p>
                        <p>Ce service n’est accessible qu’aux Utilisateurs Professionnels ayant souscrit un Abonnement dans les conditions de l&apos;Article 2.1.1.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.3. SERVICE DE GESTION DES PLANNINGS</p>
                        <p>Le service de gestion des plannings permet aux Utilisateurs Professionnels de créer des calendriers ou plannings permettant de recueillir les préférences des convives, Utilisateurs Particuliers, quant aux horaires où ils souhaitent déjeuner ou dîner et anticiper le flux d’activité au sein des restaurants, restaurants collectifs, restaurants hôteliers, cafés, bars ou cafétérias, de modifier lesdits calendriers ou plannings ainsi générés, de déterminer les Utilisateurs Particuliers susceptibles de consulter les calendriers ou plannings et de supprimer les calendriers ou plannings.</p>
                        <p>Ce service n’est accessible qu’aux Utilisateurs Professionnels ayant souscrit un Abonnement dans les conditions de l&apos;Article 2.1.1.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.4. SERVICE DE RÉASSORT</p>
                        <p>Le service de réassort permet aux Utilisateurs Professionnels d’optimiser le service afin de leur permettre de minimiser les déchets alimentaires générés et de maximiser leurs profits, le tout dans une démarche écoresponsable. </p>
                        <p>Ce service n’est accessible qu’aux Utilisateurs Professionnels ayant souscrit un Abonnement dans les conditions de l&apos;Article 2.1.1.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.5. ANNONCES RELATIVES À LA VENTE DE PRODUITS ALIMENTAIRES</p>
                        <p>Le service d’annonces relatives à la vente de produits alimentaires permet aux Utilisateurs Professionnels de proposer à la vente les repas, produits alimentaires et boissons (alcoolisées ou non) excédentaires, restés invendus et susceptibles d’être gaspillés à la suite d’un service au sein d’un établissement de restauration, d’hôtellerie ou de restauration collective. </p>
                        <p>l&apos;Utilisateur Professionnel est responsable de l’intégralité des annonces qu’il publie, de la qualité des repas, des produits alimentaires et des boissons qu’il propose à la vente. Il est intégralement responsable de l&apos;exécution des commandes qu’il reçoit par l’intermédiaire de l&apos;Application et du respect des éventuelles consignes fournies par le commanditaire (ce dernier ne pouvant être qu’un Utilisateur Particulier). </p>
                        <p>l&apos;Utilisateur Professionnel est responsable de toute offre promotionnelle qu’il propose dans le cadre de ce service. </p>
                        <p>La Société se réserve le droit de supprimer discrétionnairement toute annonce ne respectant pas le cadre légal et réglementaire. </p>
                        <p>Ce service n’est accessible qu’aux Utilisateurs Professionnels ayant souscrit un Abonnement dans les conditions de l&apos;Article 2.1.1.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.6. SERVICE DE CONSULTATION DES MENUS</p>
                        <p>Le service de consultation des menus permet à un Utilisateur Particulier de consulter des menus générés par un Utilisateur Professionnel, ce dernier ayant généré le ou lesdits menus en utilisant le service décrit à l&apos;Article 2.1.2., et permet de voter pour l&apos;Un des menus afin de manifester sa préférence. </p>
                        <p>Ce service est gratuit. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.7. SERVICE DE CONSULTATION DE PLAGES HORAIRES</p>
                        <p>Le service de consultation des plages horaires permet à un Utilisateur Particulier de notifier ses préférences relatives à un créneau horaire et à une date précise sur un calendrier ou un planning généré par un Utilisateur Professionnel, ce dernier ayant généré le calendrier ou le planning en utilisant le service décrit à l&apos;Article 2.1.3.. </p>
                        <p>Ce service est gratuit.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.1.8. SERVICE DE COMMANDE DE PRODUITS ALIMENTAIRES</p>
                        <p>Le service de commandes de repas, de produits alimentaires et de boissons (alcoolisées ou non) permet à un Utilisateur Particulier de commander, via l&apos;Application des repas, des produits alimentaires et des boissons (alcoolisées ou non) mis à disposition par les  Utilisateurs Professionnels, ces derniers ayant généré des annonces en utilisant le service décrit à l&apos;Article 2.1.5 Le prix desdits repas, produits alimentaires et boissons sont fixés par les Utilisateurs Professionnels auteurs des annonces. Ils assument seuls l&apos;exécution de la commande, la Société n’intervenant qu’en qualité d&apos;intermédiaire. </p>
                        <p>Le prix de l&apos;Article peut être complété par des frais de service dont les tarifs sont définis par la Société.</p>
                        <p>Le prix total affiché sur l&apos;Application à l’instant de la commande est le seul applicable. Le prix total fait état du tarif des frais de service, du prix du produit commandé et du montant des taxes en vigueur (taxe sur la valeur ajoutée). </p>
                        <p>La Société refusera nécessairement toute commande portant sur une boisson alcoolisée formulée par un Utilisateur Particulier de moins de dix-huit (18) ans. En cas de doute sur l’âge de l&apos;Utilisateur Particulier, la Société se réserve le droit de demander un portrait photographique récent accompagné d’une pièce d&apos;identité à l&apos;Utilisateur Particulier ou d’annuler discrétionnairement la commande. </p>
                        <p>La Société ne saurait être tenue responsable de la qualité des produits alimentaires, de la préparation de ces derniers ou de la présence d’allergènes. Seul l&apos;Utilisateur Professionnel ayant préparé la commande engage sa responsabilité.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.2. MODALITÉS DE PAIEMENT</p>
                        <p>Le paiement est exigible à la commande du service concerné.</p>
                        <p>Plusieurs moyens de paiement sont proposés à l&apos;Utilisateur :</p>
                        <p>Les paiements par carte bancaire ou par portefeuille électronique sont réalisés par les systèmes « MONETICO® », Apple Pay® ou Google Pay®. </p>
                        <p>Dans l’hypothèse d’un Abonnement (tel qu’identifié à l&apos;Article 2.1.1 ci-avant), il est possible de recourir à un paiement par virement ou prélèvement bancaire. Le paiement est dû sur option mensuellement ou annuellement à la date anniversaire de la commande. Le premier versement intervient le jour de la commande. Dans l’hypothèse d’une option pour un prélèvement mensuel, le prix sera prélevé le premier jour de chaque mois et l&apos;Utilisateur Professionnel sera uniquement redevable le jour de la commande d’une mensualité minorée au prorata temporis et prenant en compte le nombre de jours entre le jour de la commande et le dernier jour du mois selon la formule ci-après : </p>
                        <p>Prix dû lors de la souscription = (prix d’une mensualité de l&apos;Abonnement souscrit / nombre de jours que compte le mois en cours)*(nombre de jours que compte le mois en cours - nombre de jours déjà écoulés depuis le début du mois en cours, jour de souscription non inclus)</p>
                        <p>Concernant les paiements intervenus dans le cadre d’une commande de produits alimentaires (telle que visée à l&apos;Article 2.1.8. ci-dessus), la Société est autorisée à recevoir le paiement en nom et pour le compte de l&apos;Utilisateur Professionnel ayant proposé à la vente le repas, le produit alimentaire ou la boisson (tel qu’il est décrit à l&apos;Article 2.1.5. ci-dessus), à charge pour elle de lui reverser la part du paiement lui revenant (prix du repas, du produit alimentaire ou de la boisson). </p>
                        <p>Après chaque paiement, l&apos;Utilisateur reçoit par courrier électronique le justificatif de paiement suivant : référence de la commande, référence client de la commande, email, nom et prénom ou dénomination sociale du client, date d’achat, mode de règlement choisi, montant de la commande, adresse de facturation.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>2.3. DÉFAUT ET RETARD DE PAIEMENT</p>
                        <p>En cas de défaut ou de retard de paiement, y compris paiement partiel, l&apos;Utilisateur est redevable de pénalités de retard exigibles le jour suivant la date limite de règlement, et dont le taux d&apos;intérêt est égal à trois fois le taux d&apos;intérêt légal. De plus, tout défaut ou retard de paiement (même partiel) des sommes dues par l&apos;Utilisateur, persistant plus de cinq (5) jours calendaires après notification de défaut ou retard de paiement adressé à l&apos;Utilisateur par courriel, entraine de plein droit et sans nécessité de notification ou mise en demeure supplémentaire : </p>
                        <p>l&apos;exigibilité immédiate de toutes les sommes restant dues par l&apos;Utilisateur, quel que soit le mode de règlement prévu ; et </p>
                        <p>la possibilité pour la Société immédiatement et sans préavis de suspendre tout ou partie des Services de l&apos;Utilisateur (y compris ceux qui ont été réglés), de refuser toute nouvelle commande ou renouvellement de Services, et de résilier, de plein droit, par courriel, la relation contractuelle en tout ou partie. </p>
                        <p>En cas de défaut ou de retard de paiement, les Utilisateurs ayant la qualité de professionnels au sens du Code de la consommation sont redevables d’une indemnité forfaitaire pour frais de recouvrement de quarante (40) euros, sans préjudice de la possibilité pour la Société de demander, sur justification, une indemnisation complémentaire lorsque les frais de recouvrement exposés sont supérieurs au montant de ladite indemnité forfaitaire.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 3 – DROIT DE RÉTRACTATION</p>
                        <p>Les Utilisateurs Professionnels n’ont pas la qualité de consommateur au sens du Code de la consommation et ne sauraient se prévaloir d’aucun droit de rétractation. </p>
                        <p>Par ailleurs, l&apos;exécution des Services emportant nécessairement la pleine exécution desdits Services avant la fin du délai de rétractation ou portant sur des repas, produits alimentaires et boissons susceptibles de se détériorer ou de périmer rapidement les Utilisateurs Particuliers ne sauraient également se prévaloir d’un quelconque droit de rétractation conformément aux dispositions de l&apos;Article L221-28 du Code de la consommation..</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 4 – DURÉE DES SERVICES - RÉSILIATION DES SERVICES</p>
                        <p>La Société s’efforce de permettre l&apos;Accès à l&apos;ensemble des Services disponibles sur le Site et/ou l&apos;Application au jour de la commande. </p>
                        <p>Tout Abonnement (tel que désigné à l&apos;Article 2.1.1.) ci-dessus peut-être conclu : </p>
                        <p>pour une durée d’un (1) mois ; ou</p>
                        <p>pour une durée d’un (1) an. </p>
                        <p>l&apos;Abonnement qu’il soit conclu pour une durée d’un (1) mois ou pour une durée d’un (1) an se renouvelle tacitement à sa date anniversaire, pour une durée ne pouvant excéder la durée pour laquelle il a été initialement contracté.</p>
                        <p>l&apos;Abonnement peut être résilié à l’initiative de l&apos;Utilisateur Professionnel à tout moment et sans juste motif par l&apos;envoi d’une lettre de résiliation adressée par courrier recommandé avec demande d’avis de réception, moyennant un préavis de deux (2) semaines minimum avant sa date de renouvellement, la date de réception faisant foi. </p>
                        <p>La Société se réserve le droit de résilier unilatéralement tout Abonnement sans motif en respectant un préavis de quatre (4) semaines. La résiliation par la Société donne droit au remboursement des sommes déjà versées par l&apos;Utilisateur Professionnel au prorata temporis. </p>
                        <p>Par ailleurs, tout Abonnement peut être résilié, à tout moment, par la Société et de plein droit sans indemnité en cas de faute grave de l&apos;Utilisateur Professionel, de violation des présentes ou dans le cas où l&apos;Attitude ou le comportement de l&apos;Utilisateur Professionnel causerait un trouble au bon fonctionnement de ses activités ou à l’ordre public, ou occasionnerait une gêne caractérisée pour les autres Utilisateurs, celle-ci pouvant non limitativement avoir pour origine la tenue de propos injuriants, haineux, racistes, sexistes ou discriminatoires. Cette faculté de résiliation de plein droit fera l’objet d’un avertissement préalable envoyé par courrier électronique et ne pourra intervenir qu’en l&apos;Absence de réponse ou à défaut de justification probante de la part de l&apos;Utilisateur Professionnel dans un délai deux (2) jours à compter de l&apos;Avertissement. Le compte de l&apos;Utilisateur Professionnel concerné pourra en revanche faire l’objet d’une suspension immédiate rendant impossible l&apos;Accès aux Services, y compris pendant ledit délai. Cette résiliation ne donne pas droit au remboursement prorata temporis des sommes versées par l&apos;Utilisateur Professionnel, lesquelles seront réputées définitivement acquises à la Société. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE  5 – RELATIONS AVEC LES UTILISATEURS </p>
                        <p>Pour toute information ou question, le service client de la Société est joignable par courrier électronique à cette adresse : contact@jabu-app.com, par courrier à cette adresse : 26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS et par téléphone au : 06 38 66 57 48.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 6 - PROPRIÉTÉ INTELLECTUELLE</p>
                        <p>Tous les éléments du Site et de l&apos;Application sont et demeurent la propriété intellectuelle exclusive de la Société. </p>
                        <p>Toute reproduction, exploitation, rediffusion ou utilisation des éléments du Site ou de l&apos;Application, qu&apos;ils soient textuels, logiciels, visuels ou sonores, est strictement interdite sous peine de poursuites, notamment pénales.</p>
                        <p>Tout lien simple ou par hypertexte, vers le Site ou l&apos;Application, est strictement interdit sans un accord écrit exprès de la Société. </p>
                        <p>La marque JABU, ainsi que le logo JABU figurant sur le Site sont des marques déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos effectués à partir des éléments du Site ou de l&apos;Application sans l&apos;autorisation expresse de la Société est prohibée, au sens des articles L713-2 et suivants du Code de la propriété intellectuelle.</p>
                        <p>Plus globalement, tout Utilisateur s’engage à respecter la propriété de la Société concernant le Site et l&apos;Application et les éléments composant le Site et l&apos;Application et, à ce titre, notamment mais non exclusivement, s’engage ne pas utiliser de robot d’aspiration de données, informations, éléments de toute nature concernant le Site ou l&apos;Application, en tout état de cause, s’interdit de procéder à toute extraction à partir du Site ou de l&apos;Application de nature à porter atteinte aux droits de la Société. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 7 - MODIFICATION DES CGVU </p>
                        <p>La Société se réserve le droit de modifier ou de mettre à jour tout ou partie des CGVU, dans le respect de la réglementation en vigueur, notamment en communiquant aux Utilisateurs la nouvelle version des CGVU, le cas échéant via le Site ou l&apos;Application à l&apos;effet que ces dernières puissent être approuvées par l&apos;Utilisateur via le Site. Les relations entre la Société et l&apos;Utilisateur sont régies par la dernière version des CGVU acceptée par l&apos;Utilisateur.</p>
                        <p>Si une ou plusieurs stipulations des CGVU sont tenues pour non valides ou déclarées comme telles en application d&apos;une loi, d&apos;un règlement ou à la suite d&apos;une décision définitive d&apos;une juridiction compétente, elles seront réputées non écrites et les autres stipulations demeureront en vigueur. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 8 – RESPONSABILITÉ</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>8.1. UTILISATION DES SERVICES </p>
                        <p>l&apos;Utilisateur est seul responsable de l&apos;Utilisation qu’il fait de l&apos;Application, des Services et du Site. Aussi, l&apos;Utilisateur est seul responsable des recherches qu’il effectue sur le Site ou l&apos;Application, des informations qu’il saisie sur les formulaires disponibles sur le Site ou l&apos;Application ainsi que des conséquences directes ou indirectes de l&apos;Utilisation par ses soins des Services et des informations obtenus par le biais de l&apos;Application, des Services ou du Site. À ce titre, notamment, il appartient à l&apos;Utilisateur de faire un usage des Services strictement conforme à la réglementation en vigueur.</p>
                        <p>En aucun cas, la Société ne saurait être tenue responsable d’un quelconque dommage, de quelque nature que ce soit, résultant de l&apos;Utilisation par l&apos;Utilisateur des Services. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>8.2 DÉTOURNEMENT DES FONCTIONNALITÉS </p>
                        <p>En outre, l&apos;Utilisateur s’engage à utiliser les fonctionnalités du Site et de l&apos;Application conformément à leurs destinations propres et à ne pas en détourner les finalités par un usage inapproprié. Plus globalement, l&apos;Utilisateur s’engage à utiliser le Site et l&apos;Application de bonne foi. l&apos;Utilisateur s’engage en outre expressément à ne pas :</p>
                        <p>transmettre un virus informatique, vers, cheval de Troie, bombe logique ou tout autre fichier, programme ou forme de code malicieux ou malveillant, destiné à endommager, interrompre, suspendre, détruire ou limiter les fonctionnalités de tout équipement informatique ou de télécommunication ;</p>
                        <p>usurper l’identité d’une autre personne, d’un Utilisateur ou d’un employé ou d’un représentant de la Société ; </p>
                        <p>utiliser des outils d’exploration en profondeur de données, des robots ou tout autre outil similaire de collecte et d’extraction de données relatives au Site ou à l&apos;Application ;</p>
                        <p>entraver le fonctionnement du Site, des serveurs ou des connexions de réseau au Site et à l&apos;Application ;</p>
                        <p>enfreindre les exigences, procédures, politiques ou règlements relatifs aux réseaux connectés au Site ou à l&apos;Application ;</p>
                        <p>modifier, adapter, sous-licencier, traduire, vendre, effectuer l’ingénierie inverse, décompiler ou désassembler tout ou partie du Site ou de l&apos;Application ;</p>
                        <p>essayer d’accéder sans autorisation au Site et à l&apos;Application ;</p>
                        <p>utiliser le Site ou l&apos;Application pour envoyer ou transmettre tout contenu qui pourrait porter préjudice à la Société de quelque manière que ce soit ; et </p>
                        <p>enfreindre de toute autre manière les droits de tiers ou les lois et règlements en vigueur. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>8.3. VIOLATION DES CGVU </p>
                        <p>En cas de violation par l&apos;Utilisateur de l&apos;Une de ses obligations stipulées par les présentes CGVU, la Société se réserve le droit de suspendre l&apos;Accès par l&apos;Utilisateur au Site ou à l&apos;Application ou de résilier le compte de l&apos;Utilisateur. En outre, l&apos;Utilisateur garantit la Société contre toute réclamation ou revendication ou recours de toute sorte résultant de toute violation par ses soins des présentes CGVU.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>8.4. LIMITES DE RESPONSABILITÉ</p>
                        <p>La Société ne saurait être tenue responsable du non-fonctionnement, d&apos;une impossibilité d&apos;accès ou du dysfonctionnement des services du fournisseur d&apos;accès des Utilisateurs et/ou du réseau internet. Il en sera de même au titre de toutes causes extérieures à la Société. Dans ce cadre, l&apos;Utilisateur déclare être bien informé des caractéristiques intrinsèques de l&apos;internet et notamment du fait :</p>
                        <p>que la transmission des informations sur l&apos;internet ne bénéficie que d&apos;une fiabilité relative, celles-ci circulant sur des réseaux hétérogènes, aux caractéristiques et capacités diverses, qui sont parfois saturés à certaines périodes de la journée et de nature à impacter les délais de téléchargement ou l&apos;accessibilité aux informations ;</p>
                        <p>que l&apos;internet est un réseau ouvert et, qu&apos;en conséquence, les informations qu&apos;il véhicule ne sont pas protégées contre les risques de détournement, d&apos;intrusion dans son système, de piratage des données, programmes et fichiers de son système, de contamination par des virus informatiques ; et</p>
                        <p>qu&apos;il lui appartient de prendre toutes les mesures appropriées de façon à protéger les données, fichiers ou programmes stockés dans son système contre la contamination par des virus comme des tentatives d&apos;intrusion dans son système. </p>
                        <p>Dans le cadre de l&apos;exécution des Services visés à l&apos;Article 2 ci-dessus, la Société ne supporte en aucun cas la responsabilité des données ou des informations figurant sur les plannings ou les menus mis à disposition par les Utilisateurs Professionnels. Elle ne peut être tenue responsable de l&apos;exactitude ou du caractère complet des ces données et informations. En effet, la Société n’intervient qu’en qualité de mandataire des Utilisateurs Professionnels et n’est à ce titre en aucun cas responsable de l&apos;exactitude ou du caractère complet des données et des informations dans la mesure où, seuls les Utilisateurs Professionnels ayant communiqué les données ou informations en sont responsables. De la même manière, la Société ne peut être tenue responsable de la qualité des repas, des produits alimentaires ou des boissons commercialisés par ces derniers. La Société n’ayant là encore qu’une qualité de mandataire. </p>
                        <p>La responsabilité de la Société ne peut être engagée pour retard ou défaillance tenant soit à un cas de force majeure, soit à un événement échappant à leur contrôle, soit encore du fait de l&apos;Utilisateur ou du fait de tout tiers en ce compris tout autre Utilisateur. </p>
                        <p>Si la responsabilité de la Société était retenue vis-à-vis d’un Utilisateur et ce pour quelque cause que ce soit, il est convenu que l&apos;Utilisateur ne pourrait prétendre à d’autres indemnités et dommages et intérêts, que le remboursement des règlements qu’il a déjà effectués pour la partie du Service où se révèle une défaillance ou une erreur imputable à la Société ou le non-paiement de cette partie du service.</p>
                        <p>Il est en outre expressément stipulé que, en tout état de cause, la responsabilité de la Société est en tout état de cause limitée aux préjudices directs à l&apos;exclusion de tous préjudices indirects, étant à ce titre convenu et accepté qu’en aucun cas, la Société ne prendra en charge l’indemnisation des dommages immatériels et/ou indirects tels que, notamment, préjudice commercial, préjudice d’exploitation, perte de bénéfice, manque à gagner, préjudices découlant d’une forclusion dans la production de créances, etc.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 9 - DONNÉES À CARACTÈRE PERSONNEL</p>
                        <p>Les informations fournies à l&apos;Utilisateur au titre de ses données à caractère personnel, et au titre de sa qualité d’Utilisateur, sont accessibles ici : <Link to='https://www.jabu-app.fr/politique-de-confidentialit%C3%A9' target='_blank'>https://www.jabu-app.fr/politique-de-confidentialité</Link></p>
                        <p>Ces dernières font également partie intégrante des présentes CGVU et, dans le cadre du parcours utilisateur, doivent être acceptées par tout Utilisateur avant toute commande de Services.</p>
                        <p>Pour toute question relative au traitement des données à caractère personnel, l&apos;Utilisateur peut contacter le Délégué à la protection des données tel qu’il est désigné dans l&apos;Annexe dédiée à la Politique de confidentialité.</p>
                        <p>Les informations relatives à la politique d&apos;utilisation des cookies font également partie intégrante des présentes CGVU et figurent également au sein de la Politique de confidentialité.</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 10 – RÉCLAMATIONS - CONTESTATIONS</p>
                        <p>Concernant toutes réclamations, l&apos;Utilisateur peut contacter le service relations clients aux coordonnées identifiées à l&apos;Article 5 ci-avant à l&apos;effet de régler toutes difficultés qu’il rencontrerait dans le cadre de l&apos;Utilisation par ses soins de l&apos;Application, du Site ou des Services et voir traiter toutes plaintes ou griefs qu’il formerait à ce titre. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>ARTICLE 11 – LOI APPLICABLE – JURIDICTION COMPÉTENTE - ENTRÉE EN VIGUEUR </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>11.1. DROIT APPLICABLE </p>
                        <p>Les présentes CGVU sont régies par le droit français. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>11.2. JURIDICTION COMPÉTENTE</p>
                        <p>En cas de différend relatif à l&apos;interprétation ou à l&apos;exécution des présentes, les Parties rechercheront, avant toute action contentieuse, un accord amiable et se communiqueront à cet effet tous les éléments d&apos;information nécessaires.</p>
                        <p>À défaut d&apos;un règlement amiable du litige dans un délai maximum de trois (3) mois, seuls seront compétents en cas de litige de toute nature ou de contestation relative à la formation ou l&apos;exécution de la commande, le tribunal judiciaire de BOBIGNY à moins que la Société ne préfère saisir toute autre juridiction compétente. </p>
                        <p>Cette clause s&apos;applique même en cas de référé, de demande incidente ou de pluralité de défendeurs ou d&apos;appel en garantie, et quels que soient le mode et les modalités de paiement, sans que les clauses attributives de juridiction pouvant exister sur les documents de l&apos;Utilisateur ne puissent faire obstacle à l&apos;application de la présente clause. </p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>11.3. DATE D’EFFET</p>
                        <p>Les présentes CGVU sont applicables à compter du 1er décembre 2023. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CGUPage;
