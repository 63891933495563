import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import InputField from '../InputField';

const SendActivate = () => {
    // Global
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refUsername, ...inputPropsUsername } = register('username', {
        required: 'Adresse email obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Mauvais format',
        }
    });

    // Call Forgot Password Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axios.post('/users/activate/token', {
                email: data.username,
            }, { withCredentials: true });

            setFlash({ message: 'Un Email a été envoyé', type: 'success' });
            navigate('/login');
        } catch (err) {
            if (err?.response?.status === 404) {
                setFlash({ message: 'Email n\'existe pas' });
            } else if (err?.response?.status === 400) {
                setFlash({ message: 'Votre compte est associé à un compte social' });
            } else {
                navigate('/500');
            }
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">Demande D&apos;activation</span>

                <span className="d-flex justify-content-center align-items-center text-center">
                    Vous avez du recevoir un mail pour activer votre compte. Si ce n&apos;est pas le cas, veuillez renseigner votre adresse email ci-dessous.
                </span>

                <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) }>
                    <InputField color="#94928B" label="Adresse email" className="login-field" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                    <button className="login-submit" type="submit">Demande d&apos;activation</button>
                </form>

                <div className="login-back text-center">
                    <span><Link to="/login"><u><b>Retour</b></u></Link></span>
                </div>
            </div>
        </>
    );
};

export default SendActivate;
